<template>
    <v-dialog v-model="isOpen" max-width="750px" persistent>
        <v-card style="position: relative;">
            <v-card-title class="px-0 py-0 flex-column " style="position: sticky; top: 0; z-index: 1; background: #fff">
                <div class="px-4 py-4 d-flex align-center" style="width: 100%; gap: 4px;">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        @click.stop="cerrarModal"
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>

                    <h6 class="text-subtitle-1 text-uppercase font-weight-bold mb-0" style="word-break: break-word; transform: translateY(2px);">
                        Modificar Año Fiscal PAC
                    </h6>
                </div>
                <v-divider style="width: 100%" />
            </v-card-title>

            <v-card-text class="px-4 py-4">
                <v-row>
                    <v-col cols="12">
                        <ValidarEdicion
                            :id_proceso.sync="idProceso"
                            :id_modificativa="42"
                            @validar="validar"
                            :key="idProceso"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-model="data.anioFiscal"
                            :items="anioFiscales"
                            item-text="anio"
                            item-value="id"
                            label="Año Fiscal"
                            outlined
                            hide-details
                            :disabled="!validado"
                        /> 
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-menu
                            ref="menuDataRange"
                            v-model="menuDataRange"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!validado"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    outlined
                                    :value="FormatDateFtn(data.fechas)"
                                    label="Fecha Contratación"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="!validado"
                                />
                            </template>
                            <!--  @change="setFiltrosFechas" -->
                            <v-date-picker
                                title="es"
                                v-model="data.fechas"                               
                                locale="es-Es"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="px-4 py-4 justify-end">
                <v-btn @click.stop="cerrarModal" text>Cancelar</v-btn>
                <v-btn @click.stop="sendData" color="secondary" :disabled="!validado">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { createLoadable, isResponseSuccesful, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import ValidarEdicion from "./etapasConfiguracion/ValidarEdicion.vue";
    export default {
        name: 'ModalModificarAnioPAC',
        components: { ValidarEdicion },
        emits: ['on-save'],
        props: {
            isOpen: { type: Boolean },
            anioFiscales: { type: Array },
            procesoCompra: { type: Object },
        },
        data() {
            return {
                // Initialize an empty array to store the fetched data
                data: {
                    anioFiscal: null,
                    fechas: ''
                },
                menuDataRange: false,
                message: createLoadable([]),
                validado: false,
            }
        },
        methods: {
            cerrarModal() {
                this.$emit('update:is-open', false);
                this.validado = false;
            },
            FormatDateFtn(date_param) {
                if (date_param) {
                    return this.moment(date_param).format("DD/MM/YYYY");
                } else {
                    return "";
                }
            },
            async sendData() {
                if (!this.procesoCompra?.id) return;
                toggleLoadable(this.message)
                const { data } = await this.services.PacProcesos.cambiarAnioPAC(this.procesoCompra?.id, this.data);
                setLoadableResponse(this.message, data, { showAlertOnSuccess: true, skipOnSuccess: true });
                this.cerrarModal();
                if (isResponseSuccesful(data)) {
                    this.$emit('on-save');
                }
            },
            validar(valor) {
                this.validado = valor;
            }
        },
        watch: {
            "procesoCompra": {
                handler(value) {
                    this.data.fechas = value?.fecha_contratacion
                    this.data.anioFiscal = value?.id_anio_fiscal_pac;
                },
                deep: true,
                immediate: true,
            },
        },
        computed: {
            idProceso() {
                return this.procesoCompra ? this.procesoCompra.id : null;
            }
        }

    }
</script>